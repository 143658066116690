 const PURCHASE_PRODUCT = 'PURCHASE_PRODUCT';
 const SHARE_EXPERIENCE = 'SHARE_EXPERIENCE';
 const PAGE_VISIT = 'PAGE_VISIT';
 const VIEW_SCENE = 'VIEW_SCENE';
 const TIME_SPENT = 'TIME_SPENT';
 const CART_VISIT = 'CART_VISIT';

export const ANALYTICS_EVENT_TYPE = {
    PAGE_VISIT,
    CART_VISIT,
    PURCHASE_PRODUCT,    
    SHARE_EXPERIENCE,
    VIEW_SCENE,
    TIME_SPENT
};
